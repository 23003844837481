<template>
  <div class="view">
    <!-- Delete Dialog -->
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirm Pipeline Deletion?</v-card-title>
        <v-card-text>{{ dialogText }}</v-card-text>
        <v-card-actions>
          <div v-if="showDataDelete" class="text-center">
            <v-spacer></v-spacer>
            <v-switch v-model="dataDelete" label="Delete input data on s3 as well"></v-switch>
          </div><br>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="confirmDelete">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- AppBar -->
    <AppBar title="Pipeline" />
    <!-- Content -->
    <v-container fluid>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <h1 class="text-h4 mb-4">Pipelines</h1>
      <hr class="wide">
      <template>
        <v-tabs>
          <!-- Tabs -->
          <v-tab @click="checkStatus">Status</v-tab>
          <v-tab>Upload vcfs</v-tab>
          <v-tab>Upload expression</v-tab>
          <v-tab>Graph Genotyping</v-tab>
          <v-tab @click="fillTestSetParams">Update Graph</v-tab>

          <!-- Status -->
          <v-tab-item>
            <v-card flat class="mt-4">
              <v-card-text>
                <v-data-table
                  :headers="statusHeaders"
                  :items="pipelines"
                >
                  <template v-slot:item.status="{ item }">
                    <v-alert
                      class="mt-4"
                      outlined
                      text
                      :type="getStatusType(item.status)"
                    >
                      {{ item.status }}
                    </v-alert>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn
                      v-if="item.pipeline === 'clustering' && item.status === state.success"
                      @click="showTree(item)"
                      dense small elevation="0" class="mr-2">
                        <v-icon>mdi-file-tree</v-icon>
                        Show Tree
                    </v-btn>
                    <v-btn
                      v-if="item.pipeline === 'clustering' && item.status === state.success"
                      @click="download(item, 'tree.dnd')"
                      dense small elevation="0" class="mr-2">
                        <v-icon>mdi-download</v-icon>
                        tree
                    </v-btn>
                    <v-btn
                      v-if="item.pipeline === 'seqexport' && item.status === state.success"
                      @click="download(item, 'fasta')"
                      dense small elevation="0" class="mr-2">
                        <v-icon>mdi-download</v-icon>
                        fasta
                    </v-btn>
                    <v-btn
                      v-if="item.pipeline === 'seqexport' && item.status === state.success"
                      @click="download(item, 'tar')"
                      dense small elevation="0" class="mr-2">
                        <v-icon>mdi-download</v-icon>
                        vcf
                    </v-btn>
                    <v-btn
                      v-if="item.pipeline === 'graphtyping' && item.status === state.success"
                      @click="uploadVCFs(item)"
                      dense small elevation="0" class="mr-2">
                        <v-icon>mdi-upload</v-icon>
                        Upload vcfs
                    </v-btn>
                    <v-btn
                      v-if="item.pipeline === 'graphtyping' && item.status === state.success"
                      @click="copyVCFPath(item)"
                      dense small elevation="0" class="mr-2">
                        <v-icon>mdi-content-copy</v-icon>
                        Copy vcf path
                    </v-btn>
                    <!-- <v-btn
                      v-if="item.pipeline === 'graphconstruction' && item.status === state.success"
                      @click="fillDB(item)"
                      dense small elevation="0" class="mr-2">
                        <v-icon>mdi-upload</v-icon>
                        Fill DB
                    </v-btn> -->
                    <v-btn @click="openDeleteDialog(item)" dense small elevation="0" class="mr-2">
                      <v-icon>mdi-delete</v-icon>
                      Delete
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- VCF UPLOAD -->
          <v-tab-item>
            <v-card flat max-width="800px" class="mt-4">
              <v-card-text>
                <h2 class="text-h7 mb-6">Adds variation tracks from a (multi-sample) vcf file into Pantograph</h2>
                <v-alert
                  text
                  outlined
                  color="deep-orange"
                >
                  <h3>Requirements:</h3>
                  <br><strong><i>Samplesheet in csv format</i></strong> (comma-separated) with the following fields:
                  <br><br>Header line <strong>MUST</strong> be: "group,reference,vcf"
                  <br><br><strong>Column 1</strong>: vcf group name
                  <br><strong>Column 2</strong>: name of reference genome (as specified in the graph)
                  <br><strong>Column 3</strong>: path of uncompressed or bgzip-compressed vcf file on s3 (.vcf, .vcf.gz)
                  <br><br><strong><i>Important: Place all vcf files listed in the samplesheet in a unique folder on s3, and make sure that you specified the correct file path in the samplesheet for each vcf file in column 3</i></strong>
                </v-alert>
                <v-form>
                  <v-text-field
                    v-model="VCFPipelineName"
                    label="Specify pipeline name"
                    :rules="requiredRules"
                    filled
                  ></v-text-field>
                  <v-file-input
                    v-model="VCFSamplesheetFile"
                    accept=".csv"
                    filled
                    :rules="requiredRules"
                    label="Upload samplesheet"
                    @change="validateVCFSamplesheet"
                    @click:clear="VCFSamplesheetObj = { content: null, dataFolder: null, isValid: false, errors: [] }"
                  ></v-file-input>
                  <span v-if="VCFSamplesheetObj.isValid && VCFSamplesheetFile" class="mt-2" style="color: green;">
                    Samplesheet is valid
                  </span>
                  <v-alert
                    v-if="VCFSamplesheetObj.errors.length > 0"
                    text
                    outlined
                    class="mt-4"
                    color="red"
                  >
                    <h3>Error:</h3>
                    <span v-for="(error, index) in VCFSamplesheetObj.errors" :key="index">{{ error }}<br></span>
                  </v-alert>
                  <v-checkbox
                    v-model="VCFIndivTracks"
                    label="Generate individual tracks for samples"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="VCFSummTracks"
                    label="Generate summarized track for samples"
                    hide-details
                  ></v-checkbox>
                  <v-btn
                    style="display: block;"
                    class="mt-4"
                    elevation="0"
                    :disabled="VCFPipelineName === '' || !VCFSamplesheetObj.isValid"
                    @click="launchVCFUpload"
                  >
                    Start upload
                  </v-btn>
                  <div v-if="VCFPipelineStarted" class="mt-2" style="color: green;">
                    <v-html>Pipeline has been successfully started. Check the status <a href="/pipelines">here</a></v-html>
                  </div>
                </v-form>
                <v-spacer></v-spacer>
                <v-alert
                  text
                  outlined
                  class="mt-4"
                  color="blue lighten-2"
                >
                  If the upload finishes successfully, the uploaded vcf tracks are automatically visible after loading a graph and switching to the graph view.
                </v-alert>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- EXPRESSION-->
          <v-tab-item>
            <v-card flat max-width="800px" class="mt-4">
              <v-card-text>
                <h2 class="text-h7 mb-6">Adds gene expression data into Pantograph</h2>
                <v-alert
                  text
                  outlined
                  color="deep-orange"
                >
                  <h3>Requirements:</h3>
                  <br><strong><i>New gene expression files are expected to be stored in this folder on s3 and in the same format as the already existing files:</i></strong>
                  <br>
                  s3://{{ bucketName }}/{{ projectPath }}db_data/gene_expression/
                  <br><br>
                  All files in this folder will be uploaded, new files as well as already existing files with changed or extended values; the new version of files will be stored in the database.
                  <br><br>
                  The file format is the following:
                  <br>
                  - Gene count matrix (gene x BioSample) in subfolder <i>gene_count_data/</i>
                  <ul>
                    <li>- Genes in row with ID in column 1 (must have the same ID as in the graph)</li>
                    <li>- BioSamples in columns with their IDs in a header line</li>
                  </ul>
                  - Description of BioSamples in subfolder <i>metadata/</i>
                  <ul>
                    <li>- tsv file (tab-delimited) with mandatory BioSample column, plus at least one of these headers: Part, Treatment, Cultivar</li>
                  </ul>
                </v-alert>
                <v-form>
                  <v-text-field
                    v-model="exprPipelineName"
                    label="Specify pipeline name"
                    :rules="requiredRules"
                    filled
                  ></v-text-field>
                  <!-- <v-text-field
                    label="Specify folder on s3 (s3://<bucket>/...)"
                    required
                    filled
                    v-model="expressionFolderPath"
                  ></v-text-field> -->
                  <v-btn
                    class="mt-2"
                    elevation="0"
                    :disabled="!exprPipelineName"
                    @click="launchExprUpload"
                  >
                    Start upload
                  </v-btn>
                  <div v-if="exprPipelineStarted" class="mt-2" style="color: green;">
                    <v-html>Pipeline has been successfully started. Check the status <a href="/pipelines">here</a></v-html>
                  </div>
                </v-form>

                <v-alert
                  v-if="exprPipelineError !== ''"
                  text
                  outlined
                  class="mt-4"
                  color="red lighten-2"
                >
                  <h3>Error:</h3>
                  {{ exprPipelineError }}
                </v-alert>

                <v-alert
                  text
                  outlined
                  class="mt-4"
                  color="blue lighten-2"
                >
                  If the upload finishes successfully, the uploaded expression data is automatically available after switching to the graph view.
                </v-alert>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- GRAPHTYPING -->
          <v-tab-item>
            <v-card flat max-width="800px" class="mt-4">
              <v-card-text>
                <h2 class="text-h7 mb-6">Aligns fastq read sequences against the pangenome graph and calls variation</h2>
                <v-alert
                  text
                  outlined
                  color="deep-orange"
                >
                  <h3>Requirements for sample sheet in csv format:</h3>
                  <br>Header line <strong>MUST</strong> be: "group,sample,fastq_1,fastq_2"
                  <br><br><strong>Column 1</strong>: Variant group name. Several genotyped lines can be grouped together. Please indicate here an identifier for the group of samples contained in the multi-sample vcf file
                  <br><strong>Column 2</strong>: Identifier of the genotyped line. This identifier will be used as the track name.
                  <br><strong>Column 3</strong>: Path to the reads1 file in fastq format on s3.
                  <br><strong>Column 4</strong>: Path to the reads1 file in fastq format on s3.
                </v-alert>
                <!-- INPUT FORM -->
                <v-form class="mt-4" ref="graphtypingForm" v-model="graphtypingFormValid">
                  <v-text-field
                    v-model="genoPipelineName"
                    filled
                    label="Pipeline name"
                    :rules="requiredRules"
                  ></v-text-field>

                  <label>Upload samplesheet:</label>
                  <v-file-input
                    v-model="genoSamplesheetFile"
                    accept=".csv"
                    label="Samplesheet"
                    filled
                    :rules="requiredRules"
                    @change="validateGenoSamplesheet"
                    @click:clear="genoSamplesheetObj = { content: null, dataFolder: null, isValid: false, errors: [] }"
                  ></v-file-input>
                  <span v-if="genoSamplesheetFile && genoSamplesheetObj.isValid" class="mt-2" style="color: green;">
                    Samplesheet is valid
                  </span>
                  <v-alert
                    v-if="genoSamplesheetObj.errors.length > 0"
                    text
                    outlined
                    class="mt-4"
                    color="red lighten-2"
                  >
                    <h3>Error in samplesheet:</h3>
                    <span v-for="(error, index) in genoSamplesheetObj.errors" :key="index">{{ error }}<br></span>
                  </v-alert>

                  <div v-if="graphTracks.length === 0">
                    <v-text-field
                      v-model="genoReference"
                      :rules="requiredRules"
                      filled
                      label="Name of the genome against which variants are reported (must be contained in the graph)"
                    ></v-text-field>
                  </div>

                  <div v-if="graphTracks.length > 0">
                    <v-select
                      v-model="genoReference"
                      :items="graphTracks"
                      :rules="requiredRules"
                      filled
                      label="Name of the genome against which variants are reported (must be contained in the graph)"
                    ></v-select>
                  </div>

                  <v-btn
                    style="display: block;"
                    class="mt-4"
                    elevation="0"
                    @click="launchGenoPipeline"
                    :disabled="!graphtypingFormValid || !genoSamplesheetObj.isValid"
                  >
                    Start pipeline
                  </v-btn>

                  <div v-if="genoPipelineStarted" class="mt-2" style="color: green;">
                    <v-html>Pipeline has been successfully started. Check the status <a href="/pipelines">here</a></v-html>
                  </div>

                  <v-alert
                    text
                    outlined
                    class="mt-4"
                    color="blue lighten-2"
                  >
                    This pipeline can take many days. When finished, the new variation tracks can be uploaded via the '<strong>Upload vcfs</strong>' button associated to this pipeline on the <a href="/pipelines">status page</a>.
                  </v-alert>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- GRAPH -->
          <v-tab-item>
            <v-card flat max-width="800px" class="mt-4">
              <v-card-text>
                <h2 class="text-h7 mb-6">Generates pangenome graph(s) and a genomic data hub</h2>
                <v-alert
                  text
                  outlined
                  color="deep-orange"
                >
                  <h3>Requirements for sample sheet in csv format:</h3>
                  Contains the data to be included in the pangenome graph
                  <!-- <br><strong><i>- Samplesheet in csv format</i></strong> containing the data to be included in the pangenome graph: -->
                  <br><br>Header line <strong>MUST</strong> be: "sample,graph,fasta,gff"
                  <br><br><strong>Column 1</strong>: genome name (as displayed in Pantograph)
                  <br><strong>Column 2</strong>: flag if genome should be included in the pangenome graph ('Y') or only in the orthogroup search ('N')
                  <br><strong>Column 3</strong>: path to the genome fasta file of the genome
                  <ul>
                    <li>- The header may not include '_'.</li>
                    <li>- Recommended is to use the chromosome name as fasta header, since it will be attached to the genome name (Column 1) to define the path name in a pangenome graph: 'genomeName_fastaHeader'.</li>
                  </ul>
                  <strong>Column 4</strong>: path to the annotation gff file of the genome
                  <ul>
                    <li>- If ommited a liftover will be performed using a reference file to be specified below.</li>
                    <li>- The sequence identifiers in the gff need to match the headers in the supplied fasta files (from Column 3).</li>
                    <li>- The sequence identifiers may not include '_'.</li>
                    <li>- We recommend to adjust the gene IDs to include the genome name (e.g. 'genomeName.geneName') and keep them short</li>
                  </ul>
                </v-alert>
                <v-btn
                  style="display: block;"
                  class="mt-4"
                  elevation="0"
                  @click="fillTestSetParams"
                >
                  Fill in parameters for test dataset
                </v-btn>
                <v-btn
                  style="display: block;"
                  class="mt-4"
                  elevation="0"
                  @click="fillProdParams"
                >
                  Fill in parameters for current dataset
                </v-btn>
                <!-- GRAPH FORM -->
                <v-form class="mt-4" ref="graphForm" v-model="graphFormValid">
                  <v-text-field
                    v-model="graphPipelineName"
                    :rules="requiredRules"
                    filled
                    label="Pipeline name"
                  ></v-text-field>

                  <label>Upload samplesheet:</label>
                  <v-file-input
                    v-model="graphSamplesheetFile"
                    accept=".csv"
                    label="Samplesheet"
                    filled
                    :rules="requiredRules"
                    @change="validateGraphSamplesheet"
                    @click:clear="graphSamplesheetObj = { content: null, dataFolder: null, isValid: false, errors: [] }"
                  ></v-file-input>
                  <span v-if="graphSamplesheetFile && graphSamplesheetObj.isValid" class="mt-2" style="color: green;">
                    Samplesheet is valid
                  </span>
                  <v-alert
                    v-if="graphSamplesheetObj.errors.length > 0"
                    text
                    outlined
                    class="mt-4"
                    color="red lighten-2"
                  >
                    <h3>Error in samplesheet:</h3>
                    <span v-for="(error, index) in graphSamplesheetObj.errors" :key="index">{{ error }}<br></span>
                  </v-alert>

                  <v-text-field
                    v-model="graphOutputFolder"
                    filled
                    label="Provide an output folder on s3 of the new dataset"
                    :rules="requiredRules"
                  ></v-text-field>

                  <v-text-field
                    v-model="graphDatasetStr"
                    filled
                    label="Provide a list of sequence names to be included in the graph"
                    :rules="requiredListRules"
                  ></v-text-field>

                  <v-text-field
                    v-model="graphReference"
                    :rules="requiredRules"
                    filled
                    label="Name of the genome for orienting the sequences in the graph"
                  ></v-text-field>

                  <v-text-field
                    v-model="graphReferenceFasta"
                    :rules="requiredRules"
                    filled
                    label="Fasta file of the reference genome used for annotation lift-over"
                  ></v-text-field>

                  <v-text-field
                    v-model="graphReferenceGff"
                    :rules="requiredRules"
                    filled
                    label="Annotation gff file of the reference genome used for annotation lift-over"
                  ></v-text-field>

                  <v-text-field
                    v-model="graphQTLPath"
                    filled
                    label="Path of the QTL file on s3"
                  ></v-text-field>

                  <v-text-field
                    v-model="graphOutgroups"
                    :rules="listRules"
                    filled
                    label="Fasta file(s) of sequences to be included in the orthogroup search"
                  ></v-text-field>

                  <v-btn
                    style="display: block;"
                    class="mt-4"
                    elevation="0"
                    @click="launchGraphUpload"
                    :disabled="!graphFormValid || !graphSamplesheetObj.isValid"
                  >
                    Start pipeline
                  </v-btn>

                  <div v-if="graphPipelineStarted" class="mt-2" style="color: green;">
                    <v-html>Pipeline has been successfully started. Check the status <a href="/pipelines">here</a></v-html>
                  </div>

                  <v-alert
                    text
                    outlined
                    class="mt-4"
                    color="blue lighten-2"
                  >
                    This pipeline will take many hours. When finished, you can browse the new pangenome in a second instance of Pantograph at either
                    <ul>
                      <li><a href="http://pg.soyverse.traitology.ag">pg.soyverse.traitology.ag</a></li>
                      <li><a href="http://pg.soyverse2.traitology.ag">pg.soyverse2.traitology.ag</a></li>
                    </ul>
                    <br>To also update functional and gene expression data as well as indices for pangenome queries, you will need to open the API of the other Pantograph instance at either
                    <ul>
                      <li><a href="http://pg-api.soyverse.traitology.ag">pg-api.soyverse.traitology.ag</a>, or</li>
                      <li><a href="http://pg-api.soyverse2.traitology.ag">pg-api.soyverse2.traitology.ag</a></li>
                    </ul>
                    <br>then login with your Pantograph credentials, and finally execute the function '<strong>POST upload/all</strong>'.
                    <br>This will fill the database with all necessary data for the second Pantograph instance.
                  </v-alert>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </template>
    </v-container>

    <!-- Alert -->
    <Alert />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import AppBar from '@/components/AppBar.vue'
import { FileValidationResult, PipelineRun } from '@/types/Types'
import { CSVValidator } from '@/utils/CSVValidator'
import { ApiQueryService } from '@/services/ApiQueryService'
import DataProvider from '@/services/DataProvider'
import Config from '@/graph/Config'
import PipelinePoller from '@/services/PipelinePoller'
import Alert from '@/components/Alert.vue'
import { VForm } from '@/$refs'

@Component({
  components: {
    AppBar,
    Alert
  }
})
export default class Pipelines extends Vue {
  breadcrumbs = [
    {
      text: 'Graph',
      to: '/graph'
    },
    {
      text: 'Pipelines',
      disabled: true
    }
  ]

  // New error handling
  requiredRules = [
    (v: string) => !!v || 'This field is required'
  ]

  requiredListRules = [
    (v: string) => !!v || 'This field is required',
    (v: string) => this.isValidList(v) || 'This field should contain a list in the format "item1, item2, ...". Found empty item which is not allowed.'
  ]

  listRules = [
    (v: string) => this.isValidList(v) || 'This field should contain a list in the format "item1, item2, ...". Found empty item which is not allowed.'
  ]

  isValidList (listString: string): boolean {
    const items = listString.replace(/^\[|\]$/g, '').split(',').map(item => item.trim()).filter(item => item !== '')
    const isValid = items.length === listString.split(',').length
    return isValid
  }

  graphFormValid = false
  graphtypingFormValid = false

  get graphForm (): VForm {
    return this.$refs.graphForm as VForm
  }

  get graphtypingForm (): VForm {
    return this.$refs.graphtypingForm as VForm
  }

  deleteDialog = false
  showDataDelete = false
  dataDelete = false
  dialogText = ''
  pipelineToDelete: PipelineRun | null = null

  // Pipelines
  pipelines: PipelineRun[] = []

  get runningPipelines () {
    return this.$store.state.pipelineStore.runningPipelines
  }

  get bucketName () {
    return process.env.VUE_APP_BUCKET_NAME
  }

  get projectPath () {
    return process.env.VUE_APP_PROJECT_PATH
  }

  get pipelineFolderName () {
    return Config.pipelineFolderName
  }

  // Status
  statusHeaders = [
    { text: 'Name', value: 'parameters.name' },
    { text: 'Pipeline', value: 'pipeline' },
    { text: 'Status', value: 'status' },
    { text: 'User', value: 'user' },
    { text: 'Start date', value: 'start_date' },
    { text: 'Action', value: 'action' }
  ]

  state = {
    success: 'SUCCEEDED',
    fail: 'FAILED',
    running: 'ACTIVE'
  }

  // ---------------------
  // VCF UPLOAD PIPELINE
  // ---------------------
  VCFPipelineName = ''
  VCFIndivTracks = true
  VCFSummTracks = false
  VCFSamplesheetFile: File | null = null
  VCFSamplesheetObj: FileValidationResult = { content: null, dataFolder: null, isValid: false, errors: [] }
  VCFPipelineStarted = false

  // ---------------------
  // EXPRESSION UPLOAD
  // ---------------------
  exprPipelineName = ''
  exprPipelineStarted = false
  exprPipelineError = ''

  // ---------------------
  // GENOTYPING PIPELINE
  // ---------------------
  genoPipelineName = ''
  genoSamplesheetFile: File | null = null
  genoSamplesheetObj: FileValidationResult = { content: null, dataFolder: null, isValid: false, errors: [] }
  genoReference = process.env.VUE_APP_DEF_GENO_REF && process.env.VUE_APP_DEF_GENO_REF !== '' ? process.env.VUE_APP_DEF_GENO_REF : ''
  genoPipelineStarted = false

  get graphTracks () {
    return [...this.$store.state.chunkStore.trackMap.keys()].map(track => track.replace(/_Chr\d{2}.*$/, ''))
  }

  // ---------------------
  // GRAPH UPDATE PIPELINE
  // ---------------------
  s3Path = ''
  graphPipelineName = ''
  graphOutputFolder = ''
  graphSamplesheetFile: File | null = null
  graphDatasetStr = ''
  graphReference = ''
  graphReferenceFasta = ''
  graphReferenceGff = ''
  graphQTLPath = ''
  graphOutgroups = ''
  graphChunks = 5
  graphOdgiSortParam = 'Ys'
  graphOdgiBins = '1, 10, 100, 1000, 10000, 100000'
  graphGenomeSize = 0

  graphSamplesheetObj: FileValidationResult = { content: null, dataFolder: null, isValid: false, errors: [] }
  graphPipelineStarted = false

  // ------------------------------------------------
  // Methods
  // ------------------------------------------------

  mounted () {
    this.checkStatus()
    if (!this.$store.state.pipelineStore.isPolling) {
      PipelinePoller.poll(20000)
    }
  }

  getStatusType (status: string): string {
    switch (status) {
      case 'Pending':
        return 'info'
      case 'Running':
        return 'warning'
      case this.state.success:
        return 'success'
      case this.state.fail:
        return 'error'
      default:
        return 'info'
    }
  }

  checkStatus () {
    if (this.pipelines) {
      this.pipelines = []
    }

    // clean some vars
    this.exprPipelineError = ''

    const promises = []
    promises.push(ApiQueryService.checkAllPipelineStatus().then((response: PipelineRun[]) => {
      console.log('Pipelines:', response)
      // Fill statusItems for display in the table from nextflow pipelines
      for (const run of response) {
        run.pipeline = run.pipeline.substring(run.pipeline.lastIndexOf('-') + 1)
        this.pipelines.push(run)
      }
    }).catch((error) => {
      console.error(error)
    }))

    // Fill statusItems for display in the table from API upload processes
    promises.push(ApiQueryService.getFile(Config.pipelineFolderName + Config.uploadPipelinesFileName)
      .then((response) => {
        const uploadPipelines = response as PipelineRun[]
        uploadPipelines.forEach((run: PipelineRun) => {
          // Check the status
          ApiQueryService.checkUploadStatus(run.name)
            .then((status) => {
              if (status.state === 'SUCCESS') {
                run.status = this.state.success
              } else if (status.state === 'FAILURE') {
                run.status = this.state.fail
              } else {
                run.status = status.state
              }
              this.pipelines.push(run)
              console.log(run, this.pipelines)
            })
            .catch((error) => {
              console.error(error)
            })
        })
      })
      .catch((error) => {
        console.log(error)
        // console.error('upload pipeline file does not exist')
      }))

    Promise.all(promises).then(() => {
      //
    })
  }

  // ---------------------
  // VCF UPLOAD PIPELINE
  // ---------------------

  validateVCFSamplesheet () {
    if (!this.VCFSamplesheetFile) {
      return
    }

    CSVValidator.validateVCFSamplesheet(this.VCFSamplesheetFile).then((result: FileValidationResult) => {
      console.log('Samplesheet data object:', result)
      this.VCFSamplesheetObj = result
    }).catch((error) => {
      alert(error)
    })
  }

  launchVCFUpload () {
    this.writeVCFUploadParamsFile()
      .then((folder: string) => {
        if (folder !== '') {
          ApiQueryService.startPipeline('run_upload_vcf', folder + Config.paramsFileName)
            .then(() => {
              this.$store.commit('pantoStore/setAlert', { enabled: true, message: 'Pipeline ' + this.VCFPipelineName + ' started. See the <a href="/pipelines">status page</a>.', type: 'info', dismissible: true })
              this.VCFPipelineStarted = true
              PipelinePoller.poll(60000)
              this.VCFSamplesheetFile = null // reset file to not start a second upload
            })
            .catch((error) => {
              console.error(error)
            })
        }
      })
  }

  writeVCFUploadParamsFile () {
    return new Promise<string>((resolve) => {
      const projectDir = 's3://' + process.env.VUE_APP_BUCKET_NAME + '/' + process.env.VUE_APP_PROJECT_PATH
      const odgisheetDir = projectDir + 'data/graphs/odgisheet.csv'
      const groupsheetDir = projectDir + 'data/vcfs/groupsheet.csv'
      const binWidthStr = '[1, 10, 100, 1000]'
      const samplesheetPath = Config.pipelineFolderName + 'vcf_upload/' + this.VCFPipelineName + '/'

      if (this.VCFSamplesheetObj.content !== null) {
        DataProvider.storeFile(
          'samplesheet.csv',
          this.VCFSamplesheetObj.content,
          samplesheetPath
        ).catch((error) => {
          console.log(error)
        }).then(() => {
          let outStr = 'projectdir: "' + projectDir + '"\n'
          outStr += 'name: "' + this.VCFPipelineName + '"\n'
          outStr += 'odgisheet: "' + odgisheetDir + '"\n'
          outStr += 'groupsheet: "' + groupsheetDir + '"\n'
          outStr += 'samplesheet: "' + projectDir + samplesheetPath + 'samplesheet.csv"\n'
          outStr += 'odgibins: ' + binWidthStr + '\n'
          if (!this.VCFIndivTracks && !this.VCFSummTracks) {
            this.VCFIndivTracks = true
          }
          outStr += 'individual: ' + (this.VCFIndivTracks ? 'true' : 'false') + '\n'
          outStr += 'summary: ' + (this.VCFSummTracks ? 'true' : 'false') + '\n'
          outStr += 'data_folder: "' + samplesheetPath.slice(0, -1) + '"\n'
          let inputFolder = (this.VCFSamplesheetObj.dataFolder as string).split('/').slice(4).join('/')
          if (inputFolder.endsWith('/')) {
            inputFolder = inputFolder.slice(0, -1)
          }
          outStr += 'vcf_folder: "' + inputFolder + '"\n'

          DataProvider.storeFile(
            Config.paramsFileName,
            outStr,
            samplesheetPath
          ).then(() => {
            resolve(samplesheetPath)
          }).catch((error) => {
            console.log(error)
          })
        })
      }
    })
  }

  // ---------------------
  // EXPRESSION UPLOAD
  // ---------------------

  launchExprUpload () {
    ApiQueryService.uploadExpression()
      .then((response) => {
        console.log('Expression upload response:', response)

        const pipe: PipelineRun = {
          name: response,
          pipeline: 'expression upload',
          status: 'ACTIVE',
          user: '',
          start_date: '',
          completion_date: '',
          parameters: {
            name: this.exprPipelineName,
            id: response
          }
        }

        this.addUploadPipeline(pipe)

        this.$store.commit('pantoStore/setAlert', {
          enabled: true,
          message: 'Pipeline ' + this.exprPipelineName + ' started. See the <a href="/pipelines">status page</a>.',
          type: 'info',
          dismissible: true
        })
        this.exprPipelineStarted = true
        PipelinePoller.poll(5000)
      })
      .catch((error) => {
        if (error.data && error.data.detail) {
          this.exprPipelineError = error.data.detail
        } else {
          this.exprPipelineError = 'Unknown error occurred'
          console.log(error)
        }
      })
  }

  addUploadPipeline (pipeline: PipelineRun) {
    return new Promise<void>((resolve) => {
      console.log('add upload pipeline:', pipeline)
      let pipelines: PipelineRun[] = []

      // Download file with upload pipelines
      const path = Config.pipelineFolderName
      const file = path + Config.uploadPipelinesFileName
      ApiQueryService.getFile(file)
        .then((response) => {
          // Fetch existing pipelines from file
          console.log('response:', response)
          pipelines = response as PipelineRun[]
        })
        .catch(() => {
          // If file does not exist yet, it will be created below
        })
        .finally(() => {
          // Add this pipeline into the pipeline file on s3
          pipelines.push(pipeline)
          console.log('JSON:', JSON.stringify(pipelines))
          DataProvider.storeFile(Config.uploadPipelinesFileName, JSON.stringify(pipelines), Config.pipelineFolderName)
            .then(() => {
              resolve()
            })
            .catch((error) => {
              console.error('error in storing file' + error)
            })
        })
    })
  }

  // ---------------------
  // GENOTYPING PIPELINE
  // ---------------------

  validateGenoSamplesheet () {
    if (!this.genoSamplesheetFile) {
      return
    }

    CSVValidator.validateGenoSamplesheet(this.genoSamplesheetFile).then((result: FileValidationResult) => {
      console.log('Samplesheet data object:', result)
      this.genoSamplesheetObj = result
    }).catch((error) => {
      console.log(error)
    })
  }

  launchGenoPipeline () {
    this.writeGenoParamsFile()
      .then((folder: string) => {
        if (folder !== '') {
          ApiQueryService.startPipeline('run_graph_typing', folder + Config.paramsFileName)
            .then(() => {
              this.$store.commit('pantoStore/setAlert', {
                enabled: true,
                message: 'Pipeline ' + this.genoPipelineName + ' started. See the <a href="/pipelines">status page</a>.',
                type: 'info',
                dismissible: true
              })
              this.genoPipelineStarted = true
              PipelinePoller.poll(300000)
              this.genoSamplesheetFile = null // reset file to not start a second run
            })
            .catch((error) => {
              console.error(error)
            })
        }
      })
  }

  writeGenoParamsFile () {
    return new Promise<string>((resolve) => {
      if (this.genoSamplesheetObj.content !== null) {
        let baseDir = 's3://' + process.env.VUE_APP_BUCKET_NAME + '/' + process.env.VUE_APP_PROJECT_PATH
        if (!baseDir.endsWith('/')) {
          baseDir += '/'
        }
        const projectDir = baseDir
        const paramFilePath = Config.pipelineFolderName + 'genotype/' + this.genoPipelineName + '/input/'

        DataProvider.storeFile(
          'samplesheet.csv',
          this.genoSamplesheetObj.content,
          paramFilePath
        ).catch((error) => {
          console.log(error)
        }).then(() => {
          let outStr = 'projectdir: "' + projectDir + '"\n'
          outStr += 'data_folder: "' + paramFilePath.slice(0, -1) + '"\n'
          outStr += 'vcf_folder: "' + paramFilePath.slice(0, -1).replace(/input$/, 'output') + '"\n'
          outStr += 'name: "' + this.genoPipelineName + '"\n'
          outStr += 'samplesheet: "' + baseDir + paramFilePath + 'samplesheet.csv"\n'
          outStr += 'reference: "' + this.genoReference + '"\n'
          outStr += 'linearIndex: "' + baseDir + Config.pipelineFolderName + 'genotype/prep_data/bwa/' + this.genoReference + '"\n'
          outStr += 'fasta: "' + baseDir + 'data/genome_seqs/' + this.genoReference + '.fasta"\n'
          outStr += 'bwaIndex: "' + baseDir + Config.pipelineFolderName + 'genotype/prep_data/bwa/index"\n'
          outStr += 'graphsheet: "' + baseDir + 'data/graphs/graphsheet.csv"\n'
          outStr += 'chunksheet: "' + baseDir + Config.pipelineFolderName + 'genotype/prep_data/chunksheet.csv"\n'
          outStr += 'runID: "' + this.genoPipelineName + '"\n'

          console.log('outStr:', outStr)
          DataProvider.storeFile(
            Config.paramsFileName,
            outStr,
            paramFilePath
          ).then(() => {
            resolve(paramFilePath)
          }).catch((error) => {
            console.log(error)
          })
        })
      }
    })
  }

  uploadVCFs (pipeline: PipelineRun) {
    // TODO replace param.runID with param.name, 2x in this function
    const samplesheetFilePath = Config.pipelineFolderName + 'genotype/' + pipeline.parameters.runID + '/output/uploadsheet.csv'
    ApiQueryService.getFile(samplesheetFilePath)
      .then(response => {
        this.VCFSamplesheetObj = {
          content: response,
          dataFolder: pipeline.parameters.vcf_folder as string,
          isValid: true,
          errors: []
        }
        this.VCFSummTracks = true
        this.VCFPipelineName = pipeline.parameters.runID as string
        this.launchVCFUpload()
      })
      .catch((error) => {
        alert(error)
      })
  }

  copyVCFPath (pipeline: PipelineRun) {
    const text = 's3://' + process.env.VUE_APP_BUCKET_NAME + '/' + process.env.VUE_APP_PROJECT_PATH + pipeline.parameters.vcf_folder
    navigator.clipboard.writeText(text)
    this.$store.commit('pantoStore/setAlert', { enabled: true, message: 'Copied to clipboard', type: 'success', duration: 1000, dismissible: false })
  }

  // ---------------------
  // GRAPH UPDATE PIPELINE
  // ---------------------

  fillTestSetParams () {
    const s3Path = 's3://' + process.env.VUE_APP_BUCKET_NAME + '/' + process.env.VUE_APP_PROJECT_PATH
    this.graphPipelineName = ''
    this.graphOutputFolder = 's3://' + process.env.VUE_APP_BUCKET_NAME + '/SOYv2'
    this.graphSamplesheetFile = null
    this.graphDatasetStr = 'Chr01'
    this.graphReference = 'Wm82a5'
    this.graphReferenceFasta = s3Path + 'testdata/update/genomes/Wm82a5.fasta'
    this.graphReferenceGff = s3Path + 'testdata/update/genomes/Wm82a5.gff'
    this.graphQTLPath = s3Path + 'testdata/update/qtl/qtl.tsv'
    this.graphOutgroups = s3Path + 'testdata/update/outgroup/Arabidopsis_thaliana.faa'
    this.graphChunks = 5
    this.graphOdgiSortParam = 'Ys'
    this.graphOdgiBins = '1, 10, 100, 1000, 10000, 100000'
    this.graphGenomeSize = 37000000
  }

  fillProdParams () {
    const s3Path = 's3://' + process.env.VUE_APP_BUCKET_NAME + '/' + process.env.VUE_APP_PROJECT_PATH
    this.graphPipelineName = ''
    this.graphOutputFolder = ''
    this.graphSamplesheetFile = null
    this.graphDatasetStr = 'Chr01, Chr02, Chr03, Chr04, Chr05, Chr06, Chr07, Chr08, Chr09, Chr10, Chr11, Chr12, Chr13, Chr14, Chr15, Chr16, Chr17, Chr18, Chr19, Chr20'
    this.graphReference = 'Wm82a5'
    this.graphReferenceFasta = s3Path + 'data/genomes/glyma.Wm82.gnm4.4PTR.genome_main.fna'
    this.graphReferenceGff = s3Path + 'data/genomes/glyma.Wm82.gnm4.ann1.T8TQ.gene_models_main.rename.gff3'
    this.graphQTLPath = s3Path + 'data/qtl.tsv'
    this.graphOutgroups = 's3://pantograph-traitology-data-dev/SOY51/data/outgroups/Arabidopsis_thaliana.faa'
    this.graphChunks = 5
    this.graphOdgiSortParam = 'Ys'
    this.graphOdgiBins = '1, 10, 100, 1000, 10000, 100000'
    this.graphGenomeSize = 62000000
  }

  validateGraphSamplesheet () {
    if (!this.graphSamplesheetFile) {
      return
    }

    CSVValidator.validateGraphSamplesheet(this.graphSamplesheetFile).then((result: FileValidationResult) => {
      console.log('Samplesheet data object:', result)
      this.graphSamplesheetObj = result
    }).catch((error) => {
      console.log(error)
    })
  }

  launchGraphUpload () {
    this.writeGraphUploadParamsFile()
      .then((folder: string) => {
        if (folder !== '') {
          ApiQueryService.startPipeline('run_graph_construction', folder + Config.paramsFileName)
            .then(() => {
              this.$store.commit('pantoStore/setAlert', {
                enabled: true,
                message: 'Pipeline ' + this.graphPipelineName + ' started. See the <a href="/pipelines">status page</a>.',
                type: 'info',
                dismissible: true
              })
              this.graphPipelineStarted = true
              PipelinePoller.poll(300000)
              this.graphSamplesheetFile = null // reset file to not start a second run
            })
            .catch((error) => {
              console.error(error)
            })
        }
      })
  }

  writeGraphUploadParamsFile () {
    return new Promise<string>((resolve) => {
      if (this.graphSamplesheetObj.content !== null) {
        let baseDir = 's3://' + process.env.VUE_APP_BUCKET_NAME + '/' + process.env.VUE_APP_PROJECT_PATH
        if (!baseDir.endsWith('/')) {
          baseDir += '/'
        }
        const projectDir = this.graphOutputFolder
        const paramFilePath = Config.pipelineFolderName + 'graph_construction/' + this.graphPipelineName + '/'

        DataProvider.storeFile(
          'samplesheet.csv',
          this.graphSamplesheetObj.content,
          paramFilePath
        ).catch((error) => {
          console.log(error)
        }).then(() => {
          let outStr = 'projectdir: "' + projectDir + '"\n'
          outStr += 'data_folder: "' + paramFilePath.slice(0, -1) + '"\n'
          outStr += 'name: "' + this.graphPipelineName + '"\n'
          outStr += 'samplesheet: "' + baseDir + paramFilePath + 'samplesheet.csv"\n'
          outStr += 'qtl: "' + this.graphQTLPath + '"\n'
          outStr += 'datasets: [' + this.graphDatasetStr + ']\n'
          outStr += 'reference: "' + this.graphReference + '"\n'
          outStr += 'reference_fasta: "' + this.graphReferenceFasta + '"\n'
          outStr += 'reference_gff: "' + this.graphReferenceGff + '"\n'
          outStr += 'outgroups: [' + this.graphOutgroups + ']\n'
          outStr += 'pipeline: "' + this.graphOdgiSortParam + '"\n'
          outStr += 'odgibins: ' + this.graphOdgiBins + '\n'
          outStr += 'genomesize: ' + this.graphGenomeSize + '\n'
          outStr += 'chunk_number: "5"\n'

          DataProvider.storeFile(
            Config.paramsFileName,
            outStr,
            paramFilePath
          ).then(() => {
            resolve(paramFilePath)
          }).catch((error) => {
            console.log(error)
          })
        })
      }
    })
  }

  // ------------------------------------------
  // ACTIONS ON FINISHED PIPELINES
  // ------------------------------------------

  showTree (pipeline: PipelineRun) {
    // Check if tree and metadata were produced
    const treeFolder = pipeline.parameters.data_folder + '/output/'
    // treeFolder = treeFolder.replace('s3://pantograph-traitology-data/SOY51/', '')
    ApiQueryService.listFiles(treeFolder)
      .catch((error) => {
        console.error(error)
      })
      .then((response: any) => {
        // console.log('Tree folder listup:', response.files)
        if (response.files.includes(pipeline.parameters.panregion + '.tree.dnd')) {
          // &&            response.data.files.includes(pipeline.parameters.panregion + '.metadata.tsv')) {
          // console.log('Tree found')

          // set output tree as the tree to automatically load
          this.$store.commit('pantoStore/setTreeToShow', treeFolder + pipeline.parameters.panregion + '.tree.dnd')

          // switch to graph view at the position stored in the pipeline yaml, and open up tree window ('showTree: true')
          this.$store.commit('chunkStore/setBinWidth', pipeline.parameters.bin_width)
          this.$store.commit('metaStore/setBlockRedraw', true)
          this.$store.commit('chunkStore/setDataset', null)
          this.$router.push({ name: 'Graph', params: { dataset: pipeline.parameters.dataset as string, startBin: `${pipeline.parameters.start_bin}`, showTree: 'true' } })
        } else {
          alert('Tree and tree metadata from this pipeline not found')
        }
      })
  }

  download (pipeline: PipelineRun, type: string) {
    let file = pipeline.parameters.data_folder + '/output/' + pipeline.parameters.panregion + '.' + type
    if (pipeline.pipeline === 'seqexport') {
      file = (pipeline.parameters.data_folder as string)
        .replace('/seqexport/', '/export_' + (type === 'tar' ? 'vcf' : type) + '/') +
        '/output/' + pipeline.parameters.panregion + '.' + type + '.gz'
    }
    console.log((pipeline.parameters.data_folder as string).replace('/seqexport/', '/export_' + type + '/'))
    if (file.endsWith('.gz')) {
      ApiQueryService.getZippedFile(file).then((url) => {
        // const blob = new Blob([response.data], { type: 'application/octet-stream' })
        // const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', pipeline.parameters.panregion + '.' + type + '.gz')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }).catch((error) => {
        this.$store.commit('pantoStore/setAlert', { enabled: true, message: 'Download not possible. ' + error, type: 'error', duration: 10000, dismissible: true })
        console.error(error)
      })
    } else {
      ApiQueryService.getFile(file).then((response) => {
        const blob = new Blob([response])
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', pipeline.parameters.panregion + '.' + type)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }).catch((error) => {
        this.$store.commit('pantoStore/setAlert', { enabled: true, message: 'Download not possible. ' + error, type: 'error', duration: 10000, dismissible: true })
        console.error(error)
      })
    }
  }

  openDeleteDialog (pipeline: PipelineRun) {
    this.pipelineToDelete = pipeline
    console.log('Pipeline to delete:', pipeline)
    this.showDataDelete = false

    this.dialogText = 'This will delete the pipeline from the database.\n'
    if (pipeline.pipeline === 'clustering') {
      this.dialogText += 'This will also delete the tree and metadata files on s3:\n' + pipeline.parameters.data_folder
    } else if (pipeline.pipeline === 'seqexport') {
      this.dialogText += 'This will also delete the fasta and vcf files on s3:\n' + pipeline.parameters.data_folder
    } else if (pipeline.pipeline === 'vcf') {
      this.dialogText += 'This will NOT delete the vcf files on s3, unless you specify below:'
      this.showDataDelete = true
    } else if (pipeline.pipeline === 'expression upload') {
      this.dialogText += 'This will NOT delete the expression data on s3.'
    } else if (pipeline.pipeline === 'graphconstruction') {
      this.dialogText = 'This will only delete the samplesheet file on s3, but not delete any other data.'
    } else if (pipeline.pipeline === 'graphtyping') {
      this.dialogText = 'This will NOT delete the generated vcf files on s3, unless you specify below:'
      this.showDataDelete = true
    } else {
      this.dialogText = 'This will delete the pipeline from the list, but no data on s3.'
    }
    this.deleteDialog = true
  }

  confirmDelete () {
    if (!this.pipelineToDelete) {
      return
    }
    this.deletePipeline(this.pipelineToDelete)
    this.deleteDialog = false
  }

  deletePipeline (pipeline: PipelineRun): void {
    // Delete pipeline on the API
    if (pipeline.pipeline !== 'expression upload') {
      ApiQueryService.deletePipeline(pipeline.name)
        .then((response) => {
          console.log('Pipeline deleted:', response)
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      ApiQueryService.deleteUploadTask(pipeline.name)
        .then((response) => {
          console.log('Upload pipeline deleted:', response)
        })
        .catch((error) => {
          console.error('Error deleting upload pipeline:', error)
        })
    }

    // Possibly delete also files on s3
    const folder = pipeline.parameters.data_folder as string
    if (pipeline.pipeline === 'seqexport' || pipeline.pipeline === 'clustering' || pipeline.pipeline === 'vcf' || pipeline.pipeline === 'graphconstruction' || pipeline.pipeline === 'graphtyping') {
      ApiQueryService.deleteFolder(folder)
        .then((response) => {
          console.log('Pipeline data deleted on s3:', response)
        })
    }
    if (pipeline.pipeline === 'seqexport') {
      // Delete also the possible two output folders
      ApiQueryService.deleteFolder(folder.replace('/seqexport/', '/export_fasta/'))
        .then((response) => {
          console.log('Pipeline output deleted on s3:', response)
        })
      ApiQueryService.deleteFolder(folder.replace('/seqexport/', '/export_vcf/'))
        .then((response) => {
          console.log('Pipeline output deleted on s3:', response)
        })
    }
    if ((pipeline.pipeline === 'vcf' || pipeline.pipeline === 'graphtyping') && this.dataDelete) {
      // Delete also the vcf files
      ApiQueryService.deleteFolder(pipeline.parameters.vcf_folder as string)
        .then((response) => {
          console.log('vcf files deleted on s3:', response)
        })
    }
    if (pipeline.pipeline === 'expression upload') {
      // Delete the entry in the upload pipeline file
      ApiQueryService.getFile(Config.pipelineFolderName + Config.uploadPipelinesFileName)
        .then((response) => {
          const pipelines = response as PipelineRun[]
          const index = pipelines.findIndex(p => p.name === pipeline.name)
          if (index !== -1) {
            console.log('pipe before delete: ', pipelines)
            pipelines.splice(index, 1)
            if (pipelines.length > 0) {
              DataProvider.storeFile(Config.uploadPipelinesFileName, JSON.stringify(pipelines), Config.pipelineFolderName)
                .then(() => {
                  console.log('Upload pipeline file updated:', JSON.stringify(pipelines))
                })
                .catch((error) => {
                  console.error('Error updating upload pipeline file:', error)
                })
            } else {
              ApiQueryService.deleteFile(Config.pipelineFolderName + Config.uploadPipelinesFileName)
                .then(() => {
                  console.log('Upload pipeline file deleted. pipelines:', pipelines)
                })
                .catch((error) => {
                  console.error('Error deleting upload pipeline file:', error)
                })
            }
          }
        })
        .catch((error) => {
          console.error('Error getting upload pipeline file:', error)
        })
    }

    // Remove pipeline from this.pipeline
    const index = this.pipelines.findIndex(p => p.name === pipeline.name)
    if (index !== -1) {
      this.pipelines.splice(index, 1)
    }

    // If contained, delete it also from runningPipelines
    this.$store.commit('pipelineStore/deleteRunningPipeline', pipeline.name)

    // TODO: delete tree metadata from global metadata :/
  }
}
</script>

<style scoped lang="scss">
.error-message {
  color: red;
}
.error-field {
  border-color: red;
}
</style>
