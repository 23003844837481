<template>
  <div>
    <!-- AppBar -->
    <AppBar />

    <!-- BottomBar -->
    <div id="bottom-bar">
      <!-- Slider -->
      <Slider @change="onSliderPositionChange" />
    </div>

    <!-- ToolBar -->
    <ToolBar
      @binWidthChanged="onBinWidthChange"
      @sortChanged="onSortChange"
      @sortOrderChanged="onSortChange"
      @redrawKeepPos="redrawKeepPos"
      @switchCellMargin="switchCellMargin"
      @switchDrawLinks="switchDrawLinks"
      @switchDenseView="switchDenseView"
      @filterTracksByCoverage="filterTracksByCoverage"
      @openJumpMenu="openJumpMenu"
      @openMetaMenu="openMetaMenu"
      @openTrackMenu="openTrackMenu"
      @openScatterplot="openScatterplot"
      @openQTLLegend="openQTLLegend"
      @openTree="openTree"
    />

    <!-- SideBar -->
    <SideBar />

    <!-- Legend -->
    <Legend />

    <!-- Loader -->
    <Loader />

    <!-- Alert -->
    <Alert />

    <!-- Scatterplot -->
    <Window
      :id="'scatterplot'"
      :enabled="$store.state.pantoStore.scatterplotState.enabled"
      @close="$store.commit('pantoStore/setScatterplotState', { enabled: false })"
      @resize="resizeScatterPlot($event)"
    >
      <Scatterplot ref="scatterPlot" />
    </Window>

    <!-- Tree -->
    <Window
      :id="'tree'"
      :enabled="$store.state.pantoStore.treeState.enabled"
      @close="$store.commit('pantoStore/setTreeState', { enabled: false })"
      @resize="resizeTree($event)"
    >
      <Tree ref="tree" />
    </Window>

    <!-- ClusterPlot -->
    <Window
      :id="'clusterplot'"
      :enabled="$store.state.pantoStore.clusterPlotState.enabled"
      @close="$store.commit('pantoStore/setClusterPlotState', { enabled: false })"
      @resize="resizeClusterPlot($event)"
    >
      <ClusterPlot ref="clusterPlot"/>
    </Window>

    <!-- Context menu -->
    <ContextMenu
      @draw="draw"
      @redrawKeepPos="redrawKeepPos"
    />

    <!-- Selection context menu -->
    <SelectionContextMenu />
    <!-- Selector -->
    <Selector />

    <!-- Graph -->
    <v-container fluid class="relative">
      <div id="graph" ref="graph"></div>
      <GraphPlaceholder />
      <QTLHighlight />
    </v-container>
  </div>
</template>

<script lang="ts">
// Vue
import { Component, Vue, Watch } from 'vue-property-decorator'
import store from '@/store'

// Services
import { PanPosService } from '@/services/PanPosService'
import DataProvider from '@/services/DataProvider'

// Utils
import SortingUtils from '@/utils/SortingUtils'
import GeneralUtils from '@/utils/GeneralUtils'

// Graph
import Graph from '@/graph/Graph'
import GraphConfig from '@/graph/Config'
import GraphPlaceholder from '@/graph/components/GraphPlaceholder.vue'
import QTLHighlight from '@/graph/components/QTLHighlight.vue'
import Tooltip from '@/graph/modules/Tooltip'

// Components
import AppBar from '@/components/AppBar.vue'
import SideBar from '@/components/SideBar.vue'
import ToolBar from '@/components/ToolBar.vue'
import Slider from '@/components/Slider.vue'
import Legend from '@/components/Legend.vue'
import Loader from '@/components/Loader.vue'
import ContextMenu from '@/components/ContextMenu.vue'
import Selector from '@/components/Selector.vue'
import SelectionContextMenu from '@/components/SelectionContextMenu.vue'
import Scatterplot from '@/components/Scatterplot.vue'
import Tree from '@/components/PhyloTree.vue'
import ClusterPlot from '@/components/ClusterPlot.vue'
import Window from '@/components/Window.vue'
import Alert from '@/components/Alert.vue'

// Xtras
import { Buffer } from 'buffer'
import { ApiQueryService } from '@/services/ApiQueryService'
import PipelinePoller from '@/services/PipelinePoller'
import { Selection } from '@/types/Types'

@Component({
  components: {
    AppBar,
    SideBar,
    ToolBar,
    Slider,
    Legend,
    Loader,
    ContextMenu,
    SelectionContextMenu,
    GraphPlaceholder,
    QTLHighlight,
    Selector,
    Scatterplot,
    Tree,
    Window,
    ClusterPlot,
    Alert
  }
})
export default class GraphView extends Vue {
  // -------------------
  // Getters / Setters
  // -------------------

  get clusterPlotState (): any {
    return this.$store.state.pantoStore.clusterPlotState
  }

  set clusterPlotState (value: any) {
    this.$store.commit('pantoStore/setClusterPlotState', value)
  }

  get jumpMenuOpen (): boolean {
    return this.$store.state.pantoStore.jumpMenuOpen
  }

  set jumpMenuOpen (value: boolean) {
    this.$store.commit('pantoStore/setJumpMenuOpen', value)
  }

  get settingsMenuOpen (): boolean {
    return this.$store.state.pantoStore.settingsMenuOpen
  }

  set settingsMenuOpen (value: boolean) {
    this.$store.commit('pantoStore/setSettingsMenuOpen', value)
  }

  get lastBinWidth () {
    return this.$store.state.chunkStore.lastBinWidth
  }

  set lastBinWidth (value) {
    this.$store.commit('chunkStore/setLastBinWidth', value)
  }

  get selectedBinWidth () {
    return this.$store.state.chunkStore.binWidth
  }

  set selectedBinWidth (value) {
    this.lastBinWidth = this.$store.state.chunkStore.binWidth
    this.$store.commit('chunkStore/setBinWidth', value)
  }

  get currentMaxBin (): number {
    return this.$store.state.chunkStore.currentMaxBin
  }

  get sortOptions () {
    return this.$store.getters['metaStore/getAvailableSortOptions']
  }

  get sortOrder () {
    return this.$store.state.metaStore.selectedSortOrder
  }

  set sortOrder (value) {
    this.$store.commit('metaStore/setSelectedSortOrder', value)
  }

  get dataset () {
    return this.$store.state.chunkStore.dataset
  }

  set dataset (value) {
    this.$store.commit('chunkStore/setDataset', value)
  }

  get selectedSortOption () {
    return this.$store.state.metaStore.selectedSortOption
  }

  set selectedSortOption (value) {
    this.$store.commit('metaStore/setSelectedSortOption', value)
  }

  get covFraction () {
    return this.$store.state.metaStore.covFraction
  }

  set covFraction (value) {
    this.$store.commit('metaStore/setLastCovFraction', this.$store.state.metaStore.covFraction)
    this.$store.commit('metaStore/setCovFraction', value)
  }

  get sliderEnabled () {
    return this.$store.state.pantoStore.sliderEnabled
  }

  set sliderEnabled (value) {
    this.$store.commit('pantoStore/setSliderEnabled', value)
  }

  get sliderPosition () {
    return this.$store.state.pantoStore.sliderPosition
  }

  get drawLinks () {
    return this.$store.state.metaStore.drawLinks
  }

  set drawLinks (value) {
    this.$store.commit('metaStore/setDrawLinks', value)
  }

  get drawInversions () {
    return this.$store.state.metaStore.drawInversions
  }

  set drawInversions (value) {
    this.$store.commit('metaStore/setDrawInversions', value)
  }

  get drawDuplications () {
    return this.$store.state.metaStore.drawDuplications
  }

  set drawDuplications (value) {
    this.$store.commit('metaStore/setDrawDuplications', value)
  }

  get selectedLinkType () {
    return this.$store.state.metaStore.selectedLinkType
  }

  set selectedLinkType (value) {
    this.$store.commit('metaStore/setSelectedLinkType', value)
  }

  get selectedMetadataToColor () {
    return this.$store.state.metaStore.selectedMetadataToColor
  }

  set selectedMetadataToColor (value) {
    this.$store.commit('metaStore/setSelectedMetadataToColor', value)
  }

  get metadataCategories () {
    return this.$store.state.metaStore.metaDataCategories
  }

  get drawCellMargin () {
    return this.$store.state.metaStore.drawCellMargin
  }

  set drawCellMargin (value) {
    this.$store.commit('metaStore/setDrawCellMargin', value)
  }

  get denseView () {
    return this.$store.state.metaStore.denseView
  }

  set denseView (value) {
    this.$store.commit('metaStore/setDenseView', value)
  }

  get nbTracks () {
    return this.$store.state.chunkStore.nbTracks
  }

  set nbTracks (value) {
    this.$store.commit('chunkStore/nbTracks', value)
  }

  get nrChunksToLoad () {
    return this.$store.state.chunkStore.nrChunksToLoad
  }

  set nrChunksToLoad (value) {
    this.$store.commit('chunkStore/setNrChunksToLoad', value)
  }

  get scale () {
    return this.$store.state.graphStore.scale
  }

  set scale (value) {
    this.$store.commit('graphStore/setScale', value)
  }

  get uploadedMetadata () {
    return this.$store.state.metaStore.uploadedMetadata
  }

  get enabledMetaCategories () {
    return this.$store.state.pantoStore.enabledMetaCategories
  }

  get disabledGraphTracks () {
    return this.$store.state.pantoStore.disabledGraphTracks
  }

  get enabledVCFTracks () {
    return this.$store.state.pantoStore.enabledVCFTracks
  }

  get vcfTracks () {
    return this.$store.state.chunkStore.vcfTracks
  }

  get disabledReadTracks () {
    return this.$store.state.pantoStore.disabledReadTracks
  }

  get selection () {
    return this.$store.getters['pantoStore/selection']
  }

  get targetPath () {
    return this.$store.state.pantoStore.targetPath
  }

  get targetPangenomePosition () {
    return this.$store.state.pantoStore.targetPangenomePosition
  }

  get targetGene () {
    return this.$store.state.pantoStore.targetGene
  }

  // -------------------
  // Watchers
  // -------------------

  // Action dispatched in JumpMenu.vue
  @Watch('targetPath')
  onTargetPathChanged (value: any) {
    this.$store.commit('graphStore/setLoading', true)
    PanPosService.getBinForPosition(this.dataset, value.name, value.position).then((result) => {
      this.$store.commit('graphStore/setLoading', false)
      const position = Math.floor(parseInt(result.data.pan_pos) / parseInt(this.selectedBinWidth))
      if (position !== 0) {
        this.draw(position, true)
      }
    }).catch((error) => {
      this.$store.commit('graphStore/setLoading', false)
      alert('Position not found')
      if (error.response) {
        console.warn(error.response.data)
      }
    })
  }

  // Action dispatched in JumpMenu.vue
  @Watch('targetPangenomePosition')
  onTargetPangenomePositionChanged (value: number) {
    if (value > 0 && value <= (this.$store.state.chunkStore.currentMaxBin * this.$store.state.chunkStore.binWidth)) {
      const bin = Number(value) / this.$store.state.chunkStore.binWidth
      this.draw(Math.round(bin), true)
    }
  }

  // Action dispatched in JumpMenu.vue
  @Watch('targetGene')
  onTargetGeneChanged () {
    this.$store.commit('graphStore/setLoading', true)
    ApiQueryService.getPanPosOfGene(this.$store.state.chunkStore.binWidth, this.targetGene).then((result) => {
      this.$store.commit('graphStore/setLoading', false)
      const bin = result.pan_pos
      if (bin !== -1) {
        this.draw(bin, true)
      } else {
        this.$store.commit('pantoStore/setAlert', {
          enabled: true,
          message: 'No positional information for that gene available',
          type: 'error',
          duration: 5000,
          dismissible: true
        })
      }
    }).catch((error) => {
      this.$store.commit('graphStore/setLoading', false)
      this.$store.commit('pantoStore/setAlert', {
        enabled: true,
        message: 'Positional information cannot be retrieved',
        type: 'error',
        duration: 5000,
        dismissible: true
      })
      if (error.response) {
        console.warn(error.response.data)
      }
    })
  }

  @Watch('uploadedMetadata')
  onUploadedMetadataChanged () {
    this.draw(this.sliderPosition[0])
  }

  @Watch('dataset')
  onDatasetChanged () {
    if (this.dataset !== null) {
      this.loadDataset(this.$store.state.chunkStore.defaultBin)
    }
  }

  @Watch('enabledMetaCategories')
  onEnabledMetaCategoriesChanged () {
    console.log('onEnabledMetaCategoriesChanged')
    this.redrawKeepPos()
  }

  // Region selection (Selector.vue)
  @Watch('selection')
  onSelectionChanged (selection: Selection) {
    Graph.selectRegionX(selection)
  }

  @Watch('selectedSortOption')
  onSelectedSortOptionChanged () {
    this.onSortChange()
  }

  @Watch('sortOrder')
  onSelectedSortOrderChanged () {
    this.onSortChange()
  }

  // Property mutated in TrackMenu.vue when the user en/disables a graphTrack
  @Watch('disabledGraphTracks')
  onGraphsChanged () {
    console.log('onGraphsChanged')
    this.redrawKeepPos()
  }

  // Property mutated in TrackMenu.vue when the user en/disables a vcfTrack
  @Watch('enabledVCFTracks')
  onVCFsChanged () {
    console.log('onVCFsChanged')
    this.redrawKeepPos()
  }

  // Property mutated in TrackMenu.vue when the user en/disables a readTrack
  @Watch('disabledReadTracks')
  onReadsChanged () {
    this.redrawKeepPos()
  }

  // --------------------
  // Methods
  // --------------------

  onSliderPositionChange () {
    this.draw(this.sliderPosition[0])
  }

  resizeTree (size: any) {
    (this.$refs.tree as any).resize(size)
  }

  resizeScatterPlot (size: any) {
    (this.$refs.scatterPlot as any).resize(size)
  }

  resizeClusterPlot (size: any) {
    (this.$refs.clusterPlot as any).resize(size)
  }

  onBinWidthChange () {
    if (!store.state.graphStore.loading) {
      this.$store.commit('chunkStore/setCurrentMaxBin', DataProvider.getZoomLevelObj(this.$store.state.chunkStore.binWidth).num_bins)
      const highlightedCol = this.$store.state.graphStore.selectedHighlight
      // console.log('1 - binwid', this.lastBinWidth, 'highlightedCol', this.$store.state.graphStore.selectedHighlight)
      let bin = 1
      if (highlightedCol && this.lastBinWidth) {
        bin = (Graph.getBinInfoForColumn(highlightedCol).binNumber - 1) * this.lastBinWidth / this.selectedBinWidth
        this.$store.commit('graphStore/setSelectedHighlight', bin)
        // console.log('2 - bin', bin, 'highlightedCol', this.$store.state.graphStore.selectedHighlight)
      } else {
        if (this.lastBinWidth) {
          bin = Math.trunc(parseInt(this.sliderPosition[0]) * this.lastBinWidth / this.selectedBinWidth)
        }
      }

      if (bin < 1) bin = 1
      if (this.selectedBinWidth >= GraphConfig.denseViewCutoff) {
        this.denseView = true
      }
      if (this.lastBinWidth && this.lastBinWidth >= GraphConfig.denseViewCutoff && this.selectedBinWidth < GraphConfig.denseViewCutoff) {
        // in case the user has manually de-selected dense mode before, keep that setting
        this.denseView = false
      }

      this.draw(bin, highlightedCol)
    }
  }

  onSortChange () {
    if (!this.$store.state.graphStore.loading) {
      SortingUtils.sortTracks('graph')
      SortingUtils.sortTracks('vcf')
      console.log('redraw onSortChange')
      this.redrawKeepPos()
    }
  }

  loadDataset (startBin = 1, callback?: () => void) {
    console.log('loadDataset', startBin, this.$store.state.chunkStore.dataset, this.$router.currentRoute.params)
    // Reset defaultBin
    this.$store.commit('chunkStore/setDefaultBin', 1)

    if ('startBin' in this.$router.currentRoute.params) {
      startBin = Number(this.$router.currentRoute.params.startBin)
    }

    // Reset graph
    Graph.cleanupOnDatasetChange()
    Graph.removeHighlight()

    // Enable slider when the first dataset starts loading
    if (this.sliderEnabled === false) this.sliderEnabled = true

    // Load index file
    this.$store.commit('graphStore/setLoading', true)
    DataProvider.checkAndLoadIndexFile().then(() => {
      // Load metadata
      DataProvider.initMetadataFromFile().then(() => {
        this.$store.commit('pantoStore/setDatasetLoaded', true)
        this.draw(startBin, true).then(() => {
          if (callback) callback()
          this.$store.commit('pantoStore/setSnapshotMenuEnabled', true)
          this.$store.commit('metaStore/setBlockRedraw', false)
          if (this.$router.currentRoute.params.showTree === 'true') {
            // The following will open the Tree Window
            this.$store.commit('pantoStore/setTreeState', { enabled: true })
          }
        })
      })
    })
  }

  // -------------------
  // SideBar

  openJumpMenu () {
    this.$store.commit('pantoStore/setSideBar', { enabled: true, target: 'JumpMenu' })
  }

  openMetaMenu () {
    this.$store.commit('pantoStore/setSideBar', { enabled: true, target: 'MetaMenu' })
  }

  openTrackMenu () {
    this.$store.commit('pantoStore/setSideBar', { enabled: true, target: 'TrackMenu' })
  }

  openQTLLegend () {
    this.$store.commit('pantoStore/setSideBar', { enabled: true, target: 'QTLLegend' })
  }

  closeGeneMenu () {
    this.$store.commit('pantoStore/setSideBar', { enabled: false, target: null })
  }

  // -------------------

  openScatterplot () {
    this.$store.commit('pantoStore/setScatterplotState', { enabled: true })
  }

  // -------------------

  openTree () {
    this.$store.commit('pantoStore/setTreeState', { enabled: true })
  }

  // -------------------

  switchCellMargin () {
    this.redraw(Graph.getBinAtViewportLeft(), this.$store.state.graphStore.selectedHighlight !== null)
  }

  switchDrawLinks () {
    this.redraw(Graph.getBinAtViewportLeft(), this.$store.state.graphStore.selectedHighlight !== null)
  }

  switchDenseView () {
    this.redraw(Graph.getBinAtViewportLeft(), this.$store.state.graphStore.selectedHighlight !== null)
  }

  filterTracksByCoverage () {
    if (this.dataset) {
      Graph.filterTracksByCoverageAndSort().then(() => {
        this.redrawKeepPos()
      })
    }
  }

  // closeToolbarMenus is deprecated
  // TODO: remove when all the menus will be added to SideBar.vue
  closeToolbarMenus () {
    this.settingsMenuOpen = false
    this.jumpMenuOpen = false
  }

  // -------------------
  // Draw / redraw
  // -------------------

  // redrawKeepPos should be an option in redraw
  // TODO: merge redraw and redrawKeepPos
  redrawKeepPos () {
    if (!this.$store.state.metaStore.blockRedraw) {
      // redrawKeepPos is called before the first dataset is loaded - for whatever reason
      // that's why we check for presence of cachedChunks here
      if (Object.keys(this.$store.state.chunkStore.cachedChunks).length > 0) {
        let startbin = Graph.getBinAtViewportLeft()
        if (this.$store.state.graphStore.selectedHighlight !== null) {
          startbin = Graph.getBinInfoForColumn(this.$store.state.graphStore.selectedHighlight).binNumber
        }
        if (startbin) this.redraw(startbin, this.$store.state.graphStore.selectedHighlight !== null)
      }
    }
  }

  redraw (startBin = 0, highlight = false) {
    return new Promise<void>((resolve) => {
      if (!this.$store.state.metaStore.blockRedraw) {
        // Close ToolBar menus
        this.closeToolbarMenus()

        // Redraw chunks
        this.$store.commit('graphStore/setLoading', true)
        Tooltip.hide()

        console.log('redraw')
        Graph.drawCachedChunks(startBin, highlight).finally(() => {
          this.$store.commit('graphStore/setLoading', false)
          resolve()
        })
      }
    })
  }

  draw (startBin = 1, highlight = false) {
    return new Promise<void>((resolve) => {
      // Close ToolBar menus
      this.closeToolbarMenus()

      // Load and draw chunks
      this.$store.commit('graphStore/setLoading', true)

      Tooltip.hide()

      Graph.loadAndDrawChunks(startBin, highlight).finally(() => {
        this.$store.commit('graphStore/setLoading', false)
        resolve()
      })
    })
  }

  restoreView (): void {
    // If the url contains a view snapshot, restore it
    if (this.$query.view === undefined) {
      this.redrawKeepPos()
    } else {
      this.restoreSnapshot()
    }
  }

  restoreSnapshot () {
    const snapshot = JSON.parse(Buffer.from(this.$query.view as string, 'base64').toString('utf-8'))
    if (snapshot.dataset) {
      this.dataset = snapshot.dataset
      if (snapshot.sliderPosition) {
        this.sliderPosition[0] = parseFloat(snapshot.sliderPosition as string)
        if (snapshot.covFraction) this.covFraction = parseFloat(snapshot.covFraction as string)
        if (snapshot.drawInversions) this.drawInversions = GeneralUtils.stringToBoolean(snapshot.drawInversions)
        if (snapshot.drawDuplications) this.drawDuplications = GeneralUtils.stringToBoolean(snapshot.drawDuplications)
        if (snapshot.drawCellMargin) this.drawCellMargin = GeneralUtils.stringToBoolean(snapshot.drawCellMargin)
        if (snapshot.scale) this.scale = parseFloat(snapshot.scale as string)
        // We wait for draw to complete
        this.loadDataset(snapshot.sliderPosition, () => {
          if (snapshot.graphTracks) this.$store.commit('chunkStore/setGraphTracks', GeneralUtils.stringToMap(snapshot.graphTracks))
          if (snapshot.selectedBinWidth) this.selectedBinWidth = parseInt(snapshot.selectedBinWidth as string)
          if (snapshot.selectedSortOption) this.selectedSortOption = snapshot.selectedSortOption
          if (snapshot.selectedSortOrder) this.sortOrder = snapshot.selectedSortOrder
          if (snapshot.selectedMetadataToColor) this.selectedMetadataToColor = snapshot.selectedMetadataToColor
          if (snapshot.drawLinks) this.drawLinks = GeneralUtils.stringToBoolean(snapshot.drawLinks)
          if (snapshot.denseView) this.denseView = GeneralUtils.stringToBoolean(snapshot.denseView)
        })
      }
    }
  }

  // -------------------

  beforeDestroy () {
    // We enable scroll when the user leaves the page
    GeneralUtils.enableScroll(document)
  }

  // -------------------

  mounted (): void {
    // --- Start Pipeline Poller ---
    PipelinePoller.poll(60000)

    // We disable scroll to prevent the user from scrolling the page
    GeneralUtils.disableScroll(document)

    // Init Graph
    Graph.init()

    this.$router.onReady(() => {
      // Check if the user has selected a dataset via the Genome view...
      if (this.$router.currentRoute.params.dataset !== undefined) {
        // The following will trigger the loadDataset function call
        this.dataset = this.$router.currentRoute.params.dataset
      } else {
        // ...else, restore the view (either from a snapshot or from the last state)
        this.restoreView()
      }
    })

    // Redraw upon window resize
    document.body.onresize = () => {
      this.redrawKeepPos()
    }
  }
}
</script>

<style lang="scss" scoped>
#graph {
  width: 100%;
  height: calc(100vh - 227px);
}

#bottom-bar {
  width: 100%;
  height: 70px;
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 0;
  padding: 20px 40px;
  background-color: #f3f3f3;
  border-top: 1px solid #d8d8d8;
  z-index: 2;
}
</style>
